import {Button, Container, Form, Image, Modal, Navbar} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import {useEffect, useLayoutEffect, useState} from "react";

function imageOfTheDay() {
  const idx = Math.floor(Math.random() * 188);
  return "images/" + ("" + idx).padStart(4, "0") + ".jpg"
}

const IMAGE = imageOfTheDay();

function App() {

  // Store data in local storage
  const [data, setData] = useState(() => {
    const saved = localStorage.getItem("data");
    const initial = JSON.parse(saved);
    return initial || {wins: 0, plays: 0};
  })

  useEffect(() => {
    localStorage.setItem("data", JSON.stringify(data));
  }, [data])

  const [[width, height], setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const [showModal, setShowModal] = useState(false);

  return (<>
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header>
        <Modal.Title>Great Scott!</Modal.Title>
      </Modal.Header>
      <Modal.Body>Wowowowow, you've won {data.wins} out of {data.plays} times. So smart!</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    <Navbar bg="dark">
      <Container>
        <Navbar.Brand style={{color: "white"}}>
          <img src="logo512.png"
               width="30"
               height="30"
               className="d-inline-block align-top"
               alt="logo"
          />{' '}
          Sqrtl
        </Navbar.Brand>
      </Container>
    </Navbar>
    <Container>
      <Image src={IMAGE} style={{maxWidth: width - 150, maxHeight: height - 200}}/>
      <p>
        What's Squirtle's mood?
      </p>
      <Form onSubmit={function (event) {
        event.preventDefault()
        // lol, just assuming they always win now
        setData({
          ...data, wins: data.wins + 1, plays: data.plays + 1
        })

        setShowModal(true)
      }}>
        <Form.Check type={"radio"} id={"hangry"} label={"Hangry"} defaultChecked></Form.Check>

        <Button variant={"primary"} type={"submit"}>Submit</Button>
      </Form>
    </Container>
  </>);
}

export default App;
